import { NavigateNext } from '@mui/icons-material';
import logoSmall from "../assets/small-logo.png";


export const renderSuggestedQuestions = (suggestedQuestions, questionsLoaded, setMessage, toggleNotify) => {
    // const words = question.split(' ');
    // const firstThreeWords = words.slice(0, 3).join(' ');
    // const remainingWords = words.slice(3).join(' ');
    const renderedQuestions = suggestedQuestions.map((question, index) => (
        <button key={index} onClick={() => {setMessage(question); toggleNotify();}} className={`suggested-question`}>
            <span className="label">{question}</span>
            <NavigateNext className="icon" />
        </button>
    ));
    return (
        <>
            <div className='example-container'>
                <img src={logoSmall} alt="logo" className='example-logo' />
                <h1 className='example-header'>How can I support you today?</h1>

                <div className={`suggested-question-container`}>
                    {questionsLoaded ?
                        [<p key="loading-message" className={`example-header sub ${questionsLoaded ? "invisible" : ""}`}>Loading some example questions to get you started:</p>, 
                        renderedQuestions]
                        :
                        [<p  key="loading-message" className={`example-header sub ${questionsLoaded ? "invisible" : ""}`}>Loading some example questions to get you started:</p>, 
                         <div key="placeholder"  style={{ height: '24rem' }}/>]
                    }
                </div>
            </div>
        </>
    );
}