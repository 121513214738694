
// This file contains the privacy policy content in Markdown format.
import React from 'react';
import ReactMarkdown from 'react-markdown';

const Privacy = () => {
    const markdown = `## Privacy Policy

Effective Date: May 19 2024

&nbsp;

1.  Introduction

Welcome to Maester. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, and protect your information when you use our app.

&nbsp;

2.  Data Collection

We collect data only when you request it, specifically related to Google Sheets and Google Drive files. The scopes we use include:

- https://www.googleapis.com/auth/drive.file: Read-only access to your sGoogle Drive.
- https://www.googleapis.com/auth/drive.readonly: Used to download the Google Sheets data you select.
- https://www.googleapis.com/auth/userinfo.email: Used to access your email address so we can correlate the data between your email and sheets.

&nbsp;

3.  Data Use

We use the collected data solely for the following purposes:

- User-Requested Access: We access your Google Sheets data only when you request it. You manually select the files you want us to access.
- Temporary Storage: We download a copy of your selected Google Sheets data to provide our services. This data is stored temporarily and is not saved permanently.
- User Control: You have full control over the files we access. You can manually update the files and delete the downloaded copies at any point.

&nbsp;

4.  Data Protection

We implement a variety of security measures to maintain the safety of your personal information:

- Secure Storage: The downloaded copies of your Google Sheets data are stored securely and are deleted after the session or when you manually delete them.
- Access Controls: Only authorized personnel have access to the temporary data stored on our servers.

&nbsp;

5.  Third-Party Services

We use the following third-party services that may have access to your data:

- Google APIs: To access and interact with your Google Sheets and Drive files upon your request. [Google Privacy Policy] (https://policies.google.com/privacy).
- AWS: To host our app and store the downloaded copies of your Google Sheets data. [AWS Privacy Policy] (https://aws.amazon.com/privacy).
- HubSpot: To access and interact with your Hubspot Objects and Properies upon your request. [HubSpot Privacy Policy] (https://legal.hubspot.com/privacy-policy).

&nbsp;

6.  User Rights

You have the following rights regarding your data:

- Access: You can request access to the personal information we hold about you.
- Correction: You can request that we correct any inaccuracies in your personal information.
- Deletion: You can delete the downloaded copies of your Google Sheets data at any point. We do not save this data permanently.

To exercise these rights, please contact us at tim (at) maester (dot) ai

&nbsp;

7.  Changes to the Privacy Policy

We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. The effective date of the current policy is at the top of this page.

&nbsp;

8.  Contact Information

If you have any questions or concerns about this Privacy Policy, please contact us at:

- tim (at) maester (dot) ai
    `;

    return (
        <div style={{ color: 'white', width: "80vw", height: "95vh", overflow: 'scroll', fontSize: "14px", padding: '5rem', margin: '5rem' }}>
            <ReactMarkdown>{markdown}</ReactMarkdown>
        </div>
    );
};

export default Privacy;

