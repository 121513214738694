import React, { useState, useEffect, useRef } from "react"
import ReactMarkdown from 'react-markdown';
import PopUp from "../components/PopUp.jsx";
import remarkGfm from 'remark-gfm';
import "../styles/screens/ChatWindow/ChatWindow.css";
import { ContentCopy, LowPriority, ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material';
import { getSuggestedQuestions } from "../functions/api/Ask";
import { rateAnswer } from "../functions/api/Feedback";
import { renderSuggestedQuestions } from "./suggestedQuestions";
import logoSmall from "../assets/small-logo.png";

function ChatWindow({ setIsThumbUp, currentThreadID, messages, toggleNotify, setMessage, username, jwtToken, setSnackbar, handleOpenPopup, detailsOpenStatus }) {
    const [suggestedQuestions, setSuggestedQuestions] = useState([]);
    const [questionsLoaded, setQuestionsLoaded] = useState(false);
    const messagesEndRef = useRef(null);
    const containerRef = useRef(null);
    const handleThumbClick = (message, isThumbUp) => {
        rateAnswer(jwtToken, currentThreadID, message.message_id, isThumbUp);
        message.is_thumbs_up = isThumbUp ? "True" : "False";
        setIsThumbUp(isThumbUp);
        handleOpenPopup("thumbs");
    };

    const copyImageToClipboard = async (image_data) => {
        try {
            // Convert base64 data to Blob
            const response = await fetch(image_data);
            const blob = await response.blob();
            // Create a new ClipboardItem
            const clipboardItem = new ClipboardItem({ [blob.type]: blob });
            // Write the ClipboardItem to the clipboard
            await navigator.clipboard.write([clipboardItem]);
            setSnackbar("message copied!", "complete");
        } catch (error) {
            setSnackbar("error copying image to clipboard", "error");
        }
    };

    const createMessage = (message, index) => {
        const isThumbUp = message.is_thumbs_up === null ? null : message.is_thumbs_up === "True";
        let processedRole;
        if (message.role === "assistant") {
            processedRole = "maester";
        } else if (message.role === "user") {
            processedRole = username || "user";
        }
        if (index > 0 && messages[index - 1].role === message.role) {
            processedRole = "";
        }
        if (!message.message_id) {
            // console.log("no message id, corresponds to: ", message);
        }
        const imageKey = message.image_data ? "-image" : "";
        return (
            (message.role === "user") ?
                <div key={"user-" + message.message_id} className="message-container user">
                    {processedRole && <div className="message-header user">{processedRole}</div>}
                    <div className="message-text user">
                        {message.content}
                    </div>
                    <div className="message-button-bar">
                        <div className="start-buttons">
                        </div>
                        <div className="end-buttons">
                            <ContentCopy className="message-button user" onClick={() => { navigator.clipboard.writeText(message.content); setSnackbar("message copied!", "complete"); }} />
                            <LowPriority className="message-button user" onClick={() => { setMessage(message.content); toggleNotify(); setSnackbar("message echoed!", "complete"); }} />
                        </div>
                    </div>
                </div>

                :

                (message.role === "assistant") ?

                    <div key={"maester-" + message.message_id + imageKey} className="message-container maester">
                        {processedRole && <img className="message-header maester icon" src={logoSmall}></img>}
                        <div className="message-text maester">
                            <ReactMarkdown remarkPlugins={[remarkGfm]} >
                                {message.content && (message.content).replace("-MAESTERTABNAME-", " ")}
                            </ReactMarkdown>
                            {message.image_data && <img src={message.image_data} alt="image" className={"image-data"} />}
                        </div>
                        {/* don't show button bar for messages that are followed by an image */}
                        {!(message.image_data) && <div className="message-button-bar">
                            {message.key !== "loading-id" &&
                                <>
                                    <div className="start-buttons">
                                        <ThumbUpOutlined
                                            className={`message-button maester ${isThumbUp === true ? 'thumbs-up' : isThumbUp === false ? 'invisible' : ''}`}
                                            onClick={() => handleThumbClick(message, true)}
                                        />
                                        <ThumbDownOutlined
                                            className={`message-button maester ${isThumbUp === true ? 'invisible' : isThumbUp === false ? 'thumbs-down' : ''}`}
                                            onClick={() => handleThumbClick(message, false)}
                                        />
                                    </div>
                                    <div className="end-buttons">
                                        <ContentCopy className="message-button maester" onClick={() => {
                                            if (message.image_data) {
                                                copyImageToClipboard(message.image_data)
                                            } else {
                                                navigator.clipboard.writeText(message.content);
                                                setSnackbar("message copied!", "complete");
                                            };

                                        }} />
                                    </div>
                                </>}
                        </div>}
                    </div>

                    :

                    ""
        );
    }

    useEffect(() => {
        const getSuggested = async () => {
            let suggested = await getSuggestedQuestions(jwtToken);
            if (suggested) {
                setSuggestedQuestions(suggested);
            }
        }

        if (window.location.pathname === "/chat" && jwtToken) {
            setQuestionsLoaded(false);
            getSuggested();
        }

        //so that it doesn't render example questions immediately if we're in a converstation and then open a new one
        else if (window.location.pathname !== "/chat") {
            setQuestionsLoaded(false);
        }

    }, [window.location.pathname, jwtToken]);

    useEffect(() => {
        if (suggestedQuestions.length > 0) {
            setTimeout(() => {
                setQuestionsLoaded(true);
            }, 450);
        }
    }, [suggestedQuestions]);

    useEffect(() => {
        const scrollToBottom = () => {
            const container = containerRef.current;
            if (container) {
                //if user scrolls more than 1 "chat window height" away, don't autoscroll
                const isNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < container.clientHeight * 1.2;
                if (isNearBottom) {
                    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
                }
            }
        };

        if (messages.length > 0) {
            scrollToBottom();
        }
    }, [messages]);

    return (
        <div ref={containerRef} className={`chat-window ${detailsOpenStatus} flex`}>
            {
                messages.length > 0 ?

                    messages.map((message, index) => (
                        createMessage(message, index)))
                    :
                    renderSuggestedQuestions(suggestedQuestions, questionsLoaded, setMessage, toggleNotify)

            }
            <span ref={messagesEndRef} />
        </div>
    );
}

export default ChatWindow;
