import { get, post } from 'aws-amplify/api';
import { getRandomQuestions } from '../utilities/funFacts';
import { NavigateNext } from '@mui/icons-material';
import logoSmall from "../../assets/small-logo.png";

import messageSuccess from '../../assets/sounds/audioSuccess.wav';
import messageError from '../../assets/sounds/audioFailure.wav';

export const getSuggestedQuestions = async (jwtToken) => {
    try {
        const suggestedQuestions = await (await get({
            apiName: 'MaesterV3API',
            path: '/api/v3/question-suggestions',
            options: { headers: { Authorization: jwtToken } }
        }).response).body.json();
        const allQuestions = suggestedQuestions
            .filter(e => e.suggested_questions && e.suggested_questions.length > 0)
            .map(e => e.suggested_questions);
        const shuffledQuestions = allQuestions.sort(() => Math.random() - 0.5);
        const topQuestions = shuffledQuestions.slice(0, 4);
        const randomQuestions = getRandomQuestions(4 - topQuestions.length);
        const questions = [...topQuestions, ...randomQuestions];
        return questions;
    }
    catch (error) {
        console.error("Failed to get suggested questions, backfilling with presets until there are enough active questions", error);
        const randomQuestions = getRandomQuestions(4);
        return randomQuestions;
    }
}

export const handleSend = async (jwtToken, audioOn, currentThreadID, setCurrentThreadID, message, setMessage, messages, setMessages, selectedModel, temperature, setIsSendDisabled, exampleMessage = null) => {
    const veryBeginning = Date.now();
    if (exampleMessage) {
        setMessage(exampleMessage);
        return;
    };
    if (message.trim() === "") return;
    setIsSendDisabled(true);
    const timestamp = Date.now().toString();
    let localMessages = [...messages, { content: message, role: "user", timestamp: timestamp, key: "user-temp" }];

    setMessages([...localMessages]);
    setMessage('');
    const askResponsePromise = post({
        apiName: 'MaesterV3API',
        path: '/api/v3/ask',
        options: {
            headers: { Authorization: jwtToken },
            body: {
                prompt: message,
                thread_id: currentThreadID,
                model_name: selectedModel,
                temperature: temperature
            }
        }
    }).response;

    await new Promise(r => setTimeout(r, 750)); // sleep 0.75 second, because it feels jarring to have the loading message appear so quickly. TODO

    localMessages = [...localMessages, { content: "Starting Maester AI processing algorithm", role: 'assistant', timestamp: timestamp, key: "loading-id" }];
    setMessages([...localMessages]);
    const timeoutPromise = new Promise(resolve => setTimeout(() => resolve({ timedOut: true }), 5000)); // 5 second timeout
    const result = await Promise.race([
        askResponsePromise.then(response => response.body.json()),
        timeoutPromise
    ]);

    if (!result.data_sources_being_accessed) {
        console.log("No data sources being accessed, loading placeholder message")
        localMessages[localMessages.length - 1].content = "Searching relevant historical queries to improve speed and accuracy"
        setMessages([...localMessages]);
    }

    const startTime = Date.now();
    const askResponse = await (await askResponsePromise).body.json();
    const elapsedTime = Date.now() - startTime;
    console.log("Elapsed time:", elapsedTime, "ms");
    if (askResponse.data_sources_being_accessed && askResponse.data_sources_being_accessed[0] !== "None" && askResponse.data_sources_being_accessed?.length > 0) {
        console.log("Data sources being accessed:", askResponse.data_sources_being_accessed)
        if (localMessages[localMessages.length - 1].role === "assistant") {
            localMessages[localMessages.length - 1].content = "Analyzing: " + askResponse.data_sources_being_accessed
            setMessages([...localMessages]);
        }
        else {
            localMessages = [...localMessages, { content: "Analyzing: " + askResponse.data_sources_being_accessed, role: 'assistant', timestamp: timestamp, key: "loading-id" }];
            setMessages([...localMessages]);
        }
    }


    // currentThreadId  ===  askResponse.thread_id and currentThreadId !== null
    // user asked a question in an existing thread that they are still on
    // currentThreadId  !==  askResponse.thread_id and currentThreadId !== null
    // user asked a question on a different thread than the one they are on
    //  currentThreadId  !==  askResponse.thread_id and currentThreadId === null 
    // user asked a question on a different thread, and then created a new thread

    let messageResponseBody;
    const MAX_ATTEMPTS = 60;
    const MIN_WAIT = 3000;  // 3 seconds
    let attempts = 0;
    try {
        let prevStepDescription = null;
        while (attempts < MAX_ATTEMPTS) {
            attempts++;

            const startTime = Date.now();
            messageResponseBody = await (await post({
                apiName: 'MaesterV3API',
                path: '/api/v3/ask',
                options: {
                    headers: { Authorization: jwtToken },
                    body: {
                        thread_id: askResponse.thread_id,
                        run_id: askResponse.run_id
                    }
                }
            }).response).body.json();

            const elapsedTime = Date.now() - startTime;
            console.log(messageResponseBody);

            // Check for step description update
            if (messageResponseBody.step_description) {

                if (prevStepDescription !== messageResponseBody.step_description) {
                    console.log(prevStepDescription, messageResponseBody.step_description)
                    prevStepDescription = messageResponseBody.step_description;
                    localMessages[localMessages.length - 1].content = messageResponseBody.step_description;

                    setMessages([...localMessages]);
                }
            }

            // Check for final message
            else {
                console.log("final message", messageResponseBody.messages)
                const formattedMessages = messageResponseBody.messages.map((msg, index) => ({
                    content: msg.content,
                    message_id: msg.message_id + (msg.image_data ? "-image" : ""),
                    image_data: msg.image_data ?? null,
                    role: msg.role,
                    timestamp: timestamp,
                    is_thumbs_up: msg.is_thumbs_up ?? null,
                })).reverse();
                setMessages([...formattedMessages]);

                if (audioOn) {
                    const audio = new Audio(messageSuccess)
                    audio.volume = 0.35;
                    audio.src = attempts >= MAX_ATTEMPTS ? messageError : messageSuccess;
                    audio.play().catch(e => console.error('Error playing audio:', e));
                }
                setCurrentThreadID(askResponse.thread_id);
                console.log("Total Time handleSend:", Date.now() - veryBeginning, "ms")
                break;
            }

            await new Promise(r => setTimeout(r, Math.max(0, MIN_WAIT - elapsedTime)));
        }

    } catch (error) {
        console.log("error sending message", error)
        setMessages([...messages, { content: "Sorry, I'm having trouble processing your request. Please try again.", role: 'assistant' }]);
        if (audioOn) {
            const audio = new Audio(messageError)
            audio.volume = 0.35;
            audio.src = messageError;
            audio.play().catch(e => console.error('Error playing audio:', e));
        }
    }

    setIsSendDisabled(false);
};
