import { post } from 'aws-amplify/api';
//Includes getThreads, getMessages from threads, render messages


//Rendering
export const renderThreads = (isSendDisabled, threads, currentThreadID, setCurrentThreadID) => {
    return (
        threads.map(([threadID, threadData], index) => (
            <button key={index} className={`button long ${currentThreadID === threadID ? "active" : ""}`} onClick={() => {if (!isSendDisabled) {setCurrentThreadID(threadID)};}}>
              <i className="material-icons-outlined" >comment</i>
              <span className={`label`}>
                {threadData.summary}
              </span>
              {/* <DeleteOutline className="end-icon" onClick={() => handleDeleteThread(jwtToken, threadID, setThreads)} /> */}
            </button>
        ))
    );
};


//API Calls
export const getAllThreads = async (jwtToken) => {
    //runs on page load to get all file names
    try {
        const responseBody = await (await post({
            apiName: 'MaesterV3API',
            path: '/api/v3/ask',
            options: {
                headers: { Authorization: jwtToken },
                body: {
                    give: "me threads" //need a body for the post request, could be anything
                }
            }
        }).response).body.json();

        return responseBody;
    } catch (error) {
        console.error('Error getting threads:', error);
        return ({});
    };
}

export const getMessages = async (jwtToken, setSnackbar, thread) => {
    //runs on page load to get all messages
    try {
        setSnackbar("loading messages for thread...", "loading");
        const responseBody = await (await post({
            apiName: 'MaesterV3API',
            path: '/api/v3/ask',
            options: {
                headers: { Authorization: jwtToken },
                body: {
                    thread_id: thread
                }
            }
        }).response).body.json();
        setSnackbar("messages loaded!", "complete");
        return responseBody;
    } catch (error) {
        setSnackbar('Error getting messages:', "error");
        return {"thread_id": thread, "messages": []};
    }
};


// export const handleDeleteThread = async (jwtToken, thread, setThreads) => {
//     console.log("deleted thread", thread)
//     return ("deleted thread");
// }