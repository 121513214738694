import { get, put, del } from 'aws-amplify/api';

//API Calls
export const getFileNames = async (jwtToken, setFileNames) => {
    //runs on page load to get all file names
    try {
        const fileKeys = await (await get({
            apiName: 'MaesterV3API',
            path: '/api/v3/files',
            options: {
                headers: { Authorization: jwtToken }
            }
        }).response).body.json();
        // TODO maybe we want to avoid giving our S3 structure to frontend, and should just give the file name from the backend
        return (fileNameSorter(fileKeys.content.map(result => result.split('/').slice(-1)[0])));
    } catch (error) {
        console.error('Error getting file names:', error);
        return ([[], [], []]);
    }
};

export const handleFilesUpload = async (jwtToken, setSnackbar, event, setFileNames, fileNames, preprocessed = false) => {
    const files = preprocessed ? event : event.target.files;
    const newFileNames = [...fileNames, ...Array.from(files).map(file => file.name.replace(/[^\w. :-]/g, ''))];
    const validExtensions = ['.csv', '.xls', '.xlsx'];
    const invalidSyntax = ["hubspot_", "googlesheets_"];
    const formData = new FormData();
    setSnackbar("processing files...", "loading");
    for (let i = 0; i < files.length; i++) {
        // Don't allow files with special characters in the name
        if (preprocessed) {
            const modifiedFile = new File([files[i]], files[i].name.replace(/[^\w. :-]/g, ''));
            files[i] = modifiedFile;
        }

        if (files[i].name.includes('(') || files[i].name.includes(')')) {
            setSnackbar('Please remove parentheses from: ' + files[i].name, "error");
            return fileNames;
        }
        if (fileNames.includes(files[i].name)) {
            setSnackbar('A file with the name: ' + files[i].name + ' already exists. Please delete it and try again.', "error"); //TODO: Give users the option to delete here
            return fileNames;
        }
        if (!validExtensions.includes(files[i].name.substring(files[i].name.lastIndexOf('.')))) {
            setSnackbar("We only accept the file types: " + validExtensions, "error");
            return fileNames;
        }
        if (!preprocessed && (files[i].name.toLowerCase().startsWith('hubspot_') || files[i].name.toLowerCase().startsWith('googlesheets_'))) {
            const syntax = files[i].name.toLowerCase().startsWith('hubspot_') ? "hubspot_" : "googlesheets_";
            setSnackbar("Please remove the prefix: " + syntax, "error");
            return fileNames;
        }

        formData.append('file-' + i, files[i])
    }
    setSnackbar("files processed. Uploading files...", "loading");
    const resultBody = await (await put({
        apiName: 'MaesterV3API',
        path: '/api/v3/files',
        options: {
            headers: { Authorization: jwtToken },
            body: formData
        }
    }).response).body.json();

    if (resultBody.failed_uploads === 0) {
        setFileNames([...new Set([...newFileNames, ...fileNames])]);
        setSnackbar("files uploaded!", "complete");
    } else {
        setSnackbar(`failed to upload files: ${resultBody.failed_uploads}`, "error");
        return fileNames;
    }
};

export const handleDeleteFile = async (jwtToken, setSnackbar, fileNameToDelete, setFileNames) => {
    let displayTabName = null;
    try {
        if (fileNameToDelete.toLowerCase().startsWith('googlesheets_')) {
            const displayName = fileNameToDelete.replace("googlesheets_", "").replace("_", " ").replace(".csv", "");
            displayTabName = displayName.split('-MAESTERTABNAME-')[1];
            setSnackbar("disconnecting " + displayTabName, "loading");
        }
        else {
            setSnackbar("deleting file: " + fileNameToDelete, "loading");
        }
        await del({
            apiName: 'MaesterV3API',
            path: '/api/v3/files',
            options: {
                headers: { Authorization: jwtToken },
                queryParams: { filename: encodeURIComponent(fileNameToDelete) }
            }
        }).response;

        setFileNames(fileNames => fileNames.filter(file => file !== fileNameToDelete));
        if (displayTabName) { setSnackbar(displayTabName + " disconnected", "complete"); }
        else { setSnackbar("file " + fileNameToDelete + " successfully deleted!", "complete"); }
    } catch (error) {
        console.error(error);
        setSnackbar("error deleting " + fileNameToDelete + ". Please try again.", "error");
        return;
    }
};



//Utility
//Make sure you build in something that moves things to the right place, this is maybe useful for the first load
const fileNameSorter = (fileNames) => {
    let otherFiles = [];
    let googleFiles = [];
    let hubspotFiles = [];
    fileNames.forEach(file => {
        if (file.toLowerCase().startsWith('hubspot_')) {
            hubspotFiles.push(file)
        }
        else if (file.toLowerCase().startsWith('googlesheets_')) {
            googleFiles.push(file)
        }
        else {
            otherFiles.push(file)
        }
    });
    return [otherFiles, googleFiles, hubspotFiles]
};